.status-indicator {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 48px;
}
.status-indicator--xs {
  width: 30px;
  height: 30px;
}
.status-indicator--sm {
  width: 40px;
  height: 40px;
}
.status-indicator .circle {
  position: absolute;
  top: 18px;
  left: 18px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
}
.status-indicator .circle-main {
  z-index: 3;
  background: #d2d2d2;
}
.status-indicator--success .circle-main {
  background: #07dd10;
}

.status-indicator--success .circle-secondary {
  background: rgba(0, 157, 21, 0.1);
}

.status-indicator--success .circle-tertiary {
  background: rgba(0, 157, 21, 0.4);
}

.status-indicator--warning .circle-main {
  background: #ffb400;
}

.status-indicator--warning .circle-secondary {
  background: rgba(255, 180, 0, 0.1);
}

.status-indicator--warning .circle-tertiary {
  background: rgba(255, 180, 0, 0.4);
}

.status-indicator--danger .circle-main {
  background: #f21700;
}

.status-indicator--danger .circle-secondary {
  background: rgba(242, 23, 0, 0.1);
}

.status-indicator--danger .circle-tertiary {
  background: rgba(242, 23, 0, 0.4);
}
.status-indicator--xs .circle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  animation-iteration-count: 0;
  will-change: transform;
}
.status-indicator--sm .circle {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 10px;
  height: 10px;
  animation-iteration-count: 0;
  will-change: transform;
}
.status-indicator .circle.circle--animated {
  animation-iteration-count: 1;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  animation-duration: 2s;
  animation-timing-function: linear;
}
.status-indicator .circle-main.circle--animated {
  animation-name: pulsate-main-infinite;
}
.status-indicator--infinite .circle.circle--animated {
  animation-iteration-count: infinite;
}
.status-indicator .circle-secondary.circle--animated {
  animation-name: pulsate-secondary-infinite;
}
.status-indicator .circle-tertiary.circle--animated {
  animation-name: pulsate-tertiary;
}

@keyframes pulsate-main {
  40% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1.5, 1.5);
  }
}

@keyframes pulsate-secondary {
  10% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(4, 4);
  }
  100% {
    transform: scale(4, 4);
  }
}

@keyframes pulsate-tertiary {
  25% {
    transform: scale(1, 1);
  }
  80% {
    transform: scale(4, 4);
    opacity: 0;
  }
  100% {
    transform: scale(4, 4);
    opacity: 0;
  }
}

@keyframes pulsate-main-infinite {
  40% {
    transform: scale(1.5, 1.5);
  }
  60% {
    transform: scale(1.5, 1.5);
  }
}

@keyframes pulsate-secondary-infinite {
  10% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(4, 4);
  }
  80% {
    transform: scale(4, 4);
  }
  100% {
    transform: scale(1, 1);
  }
}
