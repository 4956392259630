.layout__join-team {
  position: relative;
  display: flex;
  flex: 1;
}
.layout__join-team .layout__join-team--container {
  max-width: 800px;
  min-width: 600px;
  margin: 60px auto auto;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 40px;
  box-shadow:
    0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
    0 0 0 0.0625rem rgb(222 222 222 / 17%);
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.layout__join-team .logo {
  width: 200px;
  display: flex;
  align-self: center;
}
.layout__join-team .logo svg {
  width: 100%;
  height: 100%;
}
.layout__join-team .logo .nord-image {
}
.layout__join-team h1 {
  margin: 12px 0;
  font-size: 22px;
}
.layout__join-team .invitation-details {
  margin: 20px 0;
  font-size: 14px;
}
.layout__join-team .invitation-details label {
}
.layout__join-team .invitation-details span {
  background: #f5f5f5;
  padding: 4px 10px;
  border-radius: 2px;
  margin-left: 10px;
}
.layout__join-team .btn {
  padding: 14px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.layout__join-team .btn b {
  margin: 0 10px;
}
