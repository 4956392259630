/* Account > General */
.usage-items {
  padding: 10px 2px;
}
.usage-items ul {
  max-width: 400px;
  display: flex;
  flex-direction: column;
}
.usage-items ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
}
.usage-items ul li label {
  font-size: 14px;
  flex-grow: 1;
}
.usage-items ul li .usage-item_no {
  font-size: 13px;
  display: flex;
  align-items: center;
}
.usage-items ul li .usage-item_progressbar-wrapper {
  width: 100%;
  flex: 1 1 100%;
  height: 7px;
  background: #f5f5f5;
  display: flex;
}
.usage-items ul li .usage-item_progressbar {
  height: 100%;
  background: #4587ff;
}
.usage-items ul li .usage-item_note {
  font-size: 12px;
  margin-top: 5px;
  color: #969696;
}
/*! RESPONSIVE DESIGN */
@media (max-width: 960px) {
  .billing-upgrade-processing .plan-wrapper {
    margin: 20px 0px;
  }
  .billing-upgrade-processing ul {
    padding: 10px 10px;
  }
  .billing-upgrade-processing ul li .currentPlan-marker {
    top: 20px;
    right: 0;
    border-top-right-radius: 4px;
  }
  .period-plans ul li {
    width: 100%;
  }
  .steps-header ul:before {
    left: 40px;
    right: 40px;
    width: initial;
  }
  .steps-header ul {
    padding: 10px 30px;
  }
}
