.sidebar {
}
.sidebarContainer {
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  background: #1f1e37;
  background: #030956;
  color: white;
  flex: 0 0 240px;
  width: 240px;
  position: fixed;
  z-index: 999;
  height: 100%;
}
@media (min-width: 1025px) {
  .sidebar__wrapper {
    overflow-y: auto;
    padding: 8px 20px;
    position: sticky;
    height: 100%;
  }
}
.sidebar__wrapper .logo {
  margin-bottom: 10px;
  padding: 10px 0px 10px 0px;
  max-width: 140px;
  margin-left: auto;
  margin-right: auto;
}
.sidebar__wrapper .logo > svg {
  width: 100%;
  height: 100%;
}
.sidebar__wrapper .logo > svg * {
  fill: white;
}
.menu {
  font-weight: 500;
  font-weight: var(--ifm-font-weight-semibold);
  overflow-x: hidden;
}
.menu .menu__list {
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  -webkit-transition: height 0.2s cubic-bezier(0.08, 0.52, 0.52, 1);
  transition: height 0.2s cubic-bezier(0.08, 0.52, 0.52, 1);
  -webkit-transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
}
.menu .menu__list-item {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.menu .menu__list-item > button {
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.menu .menu__list-item > button > a {
  width: 100%;
  padding: 10px 18px;
  display: flex;
  align-items: center;
}
.menu .menu__list-item > button > a > svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.menu .menu__list-item > button > a > svg * {
  fill: white;
}
.menu .menu__link {
  border-radius: 0.25rem;
  color: #606770;
  color: var(--ifm-menu-color);
  cursor: pointer;
  display: flex;
  line-height: 20px;
  text-decoration: none;
  margin: 0;
  padding: 8px 10px;
  align-items: center;
}
.menu .menu__link:hover {
  background: #1853db3b;
}
.menu .menu__link.is-active {
  background: #1853db59;
}
.menu .menu__link.is-active:hover {
  background: #1853db3b;
}
.menu .menu__link svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.menu .menu__link svg * {
  fill: white;
}
.menu .menu__link span {
}
/* ! RESPONSIVE DESIGN */
.sidebar-close {
  display: none;
}
@media (max-width: 1024px) {
  .sidebarContainer {
    padding-top: 50px;
    overflow-y: auto;
  }
  .sidebarContainer {
    transform: translateX(-100%);

    -webkit-transform: translateX(-100%);

    transition: transform 100ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;

    -webkit-transition: -webkit-transform 200ms
      cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;

    z-index: 9999;
  }
  .sidebarContainer {
    padding-top: 50px;
    overflow-y: auto;
  }
  .sidebarContainer.active {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  .sidebar-close {
    content: '';
    height: 52px;
    position: absolute;
    top: 0;
    border-bottom: 1px solid #090913;
    width: 100%;
    text-align: left;
    background-position: 10px center;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: #14132b;
  }
  .sidebar-close svg {
    height: 24px;
    fill: white;
    padding-left: 16px;
  }
  .sidebar-close svg > * {
  }
}
