.billing-current_plan {
  background: #ffffff !important;
  padding: 0 10px 0 0 !important;
  font-size: 14px;
  align-items: center;
  display: flex;
  margin: 10px 0 !important;
}
.billing-current_plan label {
  margin-right: 20px;
  font-weight: 600;
  padding: 20px 20px;
  background: #1a53db;
  color: white;
  border-radius: 4px 0 0 4px;
}
.billing-current_plan.alert-danger label {
  background: #f44336;
}
.billing-current_plan > div {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.billing-current_plan .alert-text {
  flex-grow: 1;
}
.billing-current_plan .alert-text > .main-text {
}
.billing-current_plan .alert-text > .second-text {
  font-size: 12px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}
.billing-current_plan .alert-text > .second-text > label {
  padding: 0;
  background: transparent;
  color: #333;
  margin-right: 10px;
}
.billing-current_plan .alert-text > .second-text > span {
  display: flex;
  align-items: center;
}
.billing-current_plan .alert-text > .second-text.danger,
.billing-current_plan .alert-text > .second-text.danger > label {
  color: #ff1100;
}
.billing-current_plan .alert-text > .second-text > span > svg {
  width: 15px;
  margin-left: 10px;
}
.billing-current_plan .alert-text > .second-text > span > svg * {
  fill: #ff1100;
}
.billing-current_plan .btn {
}
.billing-current_plan .btn img {
  width: 15px;
  margin-left: 10px;
}
.billing-current_plan.alert-danger span {
  color: #f44336;
}
.billing-current_plan span.selected-plan {
  color: #1a53db;
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 6px;
  margin-left: 6px;
}
.billing-current_plan span.selected-period {
  color: #1a53db;
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 6px;
}
.steps-header {
  display: flex;
  padding: 20px 0 10px 0;
  justify-content: center;
  width: 100%;
}
.steps-header ul {
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-width: 700px;
}
.steps-header ul:before {
  content: '';
  position: absolute;
  top: 33%;
  width: 100%;
  height: 1.5px;
  background: #e0e0e0;
  z-index: 0;
}
.steps-header ul li {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.steps-header ul li:after {
  content: '';
  position: absolute;
}
.steps-header ul li.active {
}
.steps-header ul li.active .id {
  background: #2196f3;
  color: white;
  border-color: transparent;
}
.steps-header ul li.active .name {
  color: #2196f3;
  font-weight: 600;
}
.steps-header ul li .id {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #e6e6e6;
  border: 1px solid #ddd;
  color: #878787;
}
.steps-header ul li .name {
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
  color: #a8a8a8;
}
.billing-upgrade-processing {
}
.billing-upgrade-processing .steps-body {
}
.billing-upgrade-processing .steps-body .billing-plans {
  display: flex;
  width: 100%;
  min-height: 100px;
  justify-content: center;
}
.billing-upgrade-processing ul {
  display: flex;
  padding: 10px 30px;
  list-style: none;
}
.billing-upgrade-processing ul li {
  margin: 0 10px;
  position: relative;
  flex: 1 1;
}
.billing-upgrade-processing ul li .currentPlan-marker {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  background: #ffc107;
  right: 20px;
  padding: 4px;
  top: 32px;
  border-radius: 4px 0 0 4px;
}
.billing-upgrade-processing li.active .plan-wrapper {
  border-color: #ffc107;
}
.billing-upgrade-processing .plan-wrapper {
  border: 1px solid #f2f2f2;
  margin: 20px 20px;
  border-radius: 4px;
  box-shadow:
    0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
    0 0 0 0.0625rem rgb(222 222 222 / 17%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 500ms ease;
  height: calc(100% - 40px);
}
.billing-upgrade-processing .plan-wrapper:hover {
  border-color: #cecececc;
}
.billing-upgrade-processing .plan-wrapper input[type='radio'] {
  display: none;
}
.billing-upgrade-processing .plan-wrapper .p-name {
  background: #f1f1f1;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  padding: 16px 20px;
  color: #353535;
}
.billing-upgrade-processing .plan-wrapper .p-options {
  flex-grow: 1;
}
.billing-upgrade-processing .plan-wrapper .p-options ul {
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 10px;
}
.billing-upgrade-processing .plan-wrapper .p-options ul li.p-opt-item {
  width: 100%;
  font-size: 13px;
  padding: 6px 10px 6px 0px;
  list-style: square;
  flex: initial;
}
.billing-upgrade-processing .plan-wrapper .p-price-wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 20px 10px 20px;
}
.billing-upgrade-processing .plan-wrapper .p-price.monthly {
  width: 100%;
  text-align: right;
  font-size: 18px;
  flex: initial;
  font-weight: bold;
  color: #545454;
}
.billing-upgrade-processing .plan-wrapper .p-price.yearly {
  margin-top: 0;
  font-size: 13px;
  color: #6b6b6b;
  text-align: right;
}
.billing-upgrade-processing .plan-wrapper .p-price span {
  font-size: 14px;
  margin-left: 5px;
}
.billing-upgrade-processing .plan-wrapper .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 15px 20px;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}
.billing-upgrade-processing li.active .plan-wrapper .btn {
  background: #ffc108;
  border: 0;
}
.billing-payment_process {
}
.billing-payment_process form {
  flex-direction: initial;
  column-gap: 20px;
}
.billing-payment_process .period-plans {
  width: 100%;
}
.billing-payment_process .preview-upcoming-invoice {
  width: auto;
  order: 1;
  border-left: 2px dashed #ddd;
  flex-grow: 1;
  padding-left: 20px;
}
.billing-payment_process .payment-form {
  width: 450px;
}
.billing-payment_process .payment-form .billingAddressPreviewCard {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 12px 18px;
  background: #f5f5f5;
  position: relative;
  cursor: pointer;
}
.billing-payment_process .payment-form .billingAddressPreviewCard:hover {
  background: #e1f0ff30;
  border-color: #c7cde5;
}
.billing-payment_process .payment-form .billingAddressPreviewCard.error {
  border-color: #f77171;
}
.billing-payment_process .payment-form .billingAddressPreviewCard h2 {
  font-size: 14px;
  color: #1565c0;
}
.billing-payment_process
  .payment-form
  .billingAddressPreviewCard
  .billingAddress_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.billing-payment_process
  .payment-form
  .billingAddressPreviewCard
  .billingAddress_wrapper
  .no-info-found {
  font-size: 11px;
  color: #777;
  text-transform: uppercase;
}
.billing-payment_process
  .payment-form
  .billingAddressPreviewCard
  .billingAddress_wrapper
  .bA-email {
  font-size: 12px;
  width: 100%;
}
.billing-payment_process
  .payment-form
  .billingAddressPreviewCard
  .billingAddress_wrapper
  .bA-name {
  font-size: 12px;
}
.billing-payment_process
  .payment-form
  .billingAddressPreviewCard
  .billingAddress_wrapper
  .bA-country {
  margin-left: 10px;
  font-size: 12px;
}
.billing-payment_process .payment-form .billingAddressPreviewCard .btn {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: 0;
  color: #0176ff;
  /* font-weight: 600; */
  text-decoration: underline;
  cursor: pointer;
}
.period-plans {
  margin: 20px;
}
.period-plans ul {
  justify-content: center;
}
.period-plans ul li {
  width: 340px;
}
.period-plans ul li.active .period-plan--wrapper {
  border-color: #2196f3a1;
  background: #2196f30a;
}
.period-plans ul li.disabled .period-plan--wrapper {
  border-color: #dededea1;
  background: #3737370a;
}
.period-plans ul li:not(.disabled) .period-plan--wrapper:hover {
  background: #2196f30a;
  border-color: #2196f3a1;
}
.period-plans ul li .period-plan--wrapper {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 14px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
.period-plans .period-plan--wrapper input[type='radio'] {
  margin: 0 10px 0 0;
}
.period-plans .period-plan--wrapper .p-name {
  flex-grow: 1;
  font-weight: 600;
  color: #696969;
  display: flex;
  align-items: center;
}
.period-plans .period-plan--wrapper .p-period-type {
  background: #3f51b5;
  text-transform: uppercase;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 2px;
  color: white;
  margin-left: 10px;
}
.period-plans .period-plan--wrapper .p-price {
  width: 100%;
  font-size: 14px;
  margin-top: 6px;
  text-align: right;
}
.period-plans .period-plan--wrapper .p-price > span {
  margin-left: 8px;
  color: #929292;
}
.period-plans .period-plan--wrapper .p-price.monthly {
}
.period-plans .period-plan--wrapper .p-price.yearly {
}
.preview-upcoming-invoice {
  width: 600px;
  margin: 0 auto;
}
.period-plans .period-plan--wrapper .currentPlan-marker {
  position: relative;
  top: initial;
  right: initial;
  margin-left: 6px;
  color: #333333;
  border-radius: 2px;
  padding: 2px 4px;
}
/** Unauthorized downgrade alert */
.unauthorized-downgrade-report {
  display: flex;
  max-width: 900px;
  margin: 10px auto;
  padding: 20px;
  background: #ffcb683b;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.unauthorized-downgrade-report img {
}
.unauthorized-downgrade-report h3 {
}
.unauthorized-downgrade-report .muted-text {
  text-align: center;
  margin: 0;
  font-size: 14px;
  margin-bottom: 10px;
}
.unauthorized-downgrade-report table {
  width: 80%;
  margin-top: 20px;
  border-spacing: 0;
  border-collapse: separate;
}
.unauthorized-downgrade-report table thead tr th {
  background: #1a53db;
  padding: 6px 10px;
  color: white;
}
.unauthorized-downgrade-report table tbody {
}
.unauthorized-downgrade-report table tbody tr td {
  background: white;
  padding: 6px 10px;
  font-size: 14px;
}
.unauthorized-downgrade-report table tbody tr:nth-child(2n + 1) td {
  background: #f5f5f5;
}
.unauthorized-downgrade-report table tbody tr td span {
}
.preview-upcoming-invoice .invoice-wrapper {
}
.preview-upcoming-invoice .invoice-wrapper .p-invoice-title {
  text-transform: uppercase;

  text-align: center;

  padding: 0 10px 10px 10px;

  font-size: 12px;

  color: #9e9e9e;

  position: relative;

  /* z-index: 6; */
}
.preview-upcoming-invoice .invoice-wrapper .p-invoice-title span {
  background: white;
  z-index: 10;
  position: relative;
  padding: 0 10px;
}
.preview-upcoming-invoice .invoice-wrapper .p-invoice-title:before {
  content: '';
  border-top: 1px solid #e0e0e0;
  position: absolute;
  left: 0;
  top: 9px;
  width: 100%;
  height: 2px;
  z-index: 0;
}
.preview-upcoming-invoice .invoice-wrapper .inv-line {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 8px 8px;
  border-bottom: 1px solid #eae9e9b0;
}
.preview-upcoming-invoice .invoice-wrapper .inv-line.amount_due {
  background: #e1f0ff;
  border-radius: 4px;
}
.preview-upcoming-invoice .invoice-wrapper .inv-line.line-footer {
  border-bottom: 0;
  width: 350px;
  margin-left: auto;
}
.preview-upcoming-invoice
  .invoice-wrapper
  .inv-line.line-footer
  .inv-line_desc {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.preview-upcoming-invoice
  .invoice-wrapper
  .inv-line.line-footer
  .inv-line_amount {
  padding-left: 40px;
  width: 110px;
  text-align: right;
}
.preview-upcoming-invoice .invoice-wrapper .inv-line_desc {
  flex-grow: 1;
}
.inv-line_desc.discount-include span {
  color: red;
  font-weight: 600;
  font-size: 14px;
}
.inv-line.line-footer.discount {
  background: #ffd70080;
  font-weight: 600;
  border-radius: 4px;
}

.preview-upcoming-invoice .invoice-wrapper .inv-line_amount {
  font-weight: 500;
}
.preview-upcoming-invoice .explanation-reason {
  font-size: 12px;
  color: white;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 3px;
}
.payment-form {
  width: 500px;
  margin: 0 auto;
}
.payment-form .field-group {
  flex-direction: column;
}
.payment-form .field-group label {
}
.payment-form .field-group .StripeElement {
  background: #fdfdfd;
  padding: 14px 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.payment-form .field-group .StripeElement.error {
  border-color: #f03232;
}
.payment-form .card-error {
  padding: 4px 4px;
  color: red;
  font-weight: 600;
  font-size: 13px;
}
.payment-form.billing-address-form {
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0px;
  column-gap: 10px;
}
.payment-form.billing-address-form .field-group {
  width: 100%;
  padding: 6px 2px;
}
.payment-form.billing-address-form .field-group.half {
  width: calc(50% - 10px);
}
.steps-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-top: 1px solid #f5f5f5;
  padding-top: 20px;
  flex-wrap: wrap;
}
.steps-footer button {
  margin-left: 10px;
}
/** RESPONSIVE */
@media (max-width: 768px) {
  .period-plans ul li {
    width: 50%;
  }
  .steps-header ul:before {
    width: auto;
  }
}
@media (max-width: 425px) {
  .tabs.big-tab .tabs-content ul {
    flex-wrap: wrap;
  }
  .period-plans ul li {
    width: 100%;
    margin: 10px;
  }
  .preview-upcoming-invoice,
  .payment-form {
    width: 100%;
  }
  .steps-header ul {
    min-width: 100%;
  }
  .steps-footer button {
    margin-bottom: 10px;
  }
}
