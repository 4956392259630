.layout__email-verify {
  position: relative;
  display: flex;
  flex: 1;
}
.layout__email-verify .layout__email-verify--container {
  max-width: 800px;
  min-width: 600px;
  margin: 60px auto auto;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 40px;
  box-shadow:
    0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
    0 0 0 0.0625rem rgb(222 222 222 / 17%);
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.layout__email-verify .logo {
  width: 200px;
  display: flex;
  align-self: center;
}
.layout__email-verify .logo svg {
  width: 100%;
  height: 100%;
}
.layout__email-verify .logo .nord-image {
}
.layout__email-verify h1 {
  margin: 12px 0;
  font-size: 22px;
}
.layout__email-verify .invitation-details {
  margin: 20px 0;
  font-size: 14px;
}
.layout__email-verify .invitation-details label {
}
.layout__email-verify .invitation-details span {
  background: #f5f5f5;
  padding: 4px 10px;
  border-radius: 2px;
  margin-left: 10px;
}
.layout__email-verify .btn {
  padding: 14px;
  margin-top: 20px;
}
.layout__email-verify .btn b {
}
.layout__email-verify .go-to-app {
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout__email-verify .go-to-app a {
}
.layout__email-verify .go-to-app a .btn {
  display: flex;
  align-items: center;
}
.layout__email-verify .go-to-app a .btn > span {
  line-height: 20px;
  margin-right: 10px;
  font-weight: 600;
}
.layout__email-verify .go-to-app a .btn > svg {
  width: 20px;
}
.layout__email-verify .go-to-app a .btn > svg * {
  fill: white;
}
