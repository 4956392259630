.layout__statuspages {
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.layout__statuspages .page__items {
  margin: 20px 0px;
  width: 100%;
  position: relative;
  flex-grow: 1;
}
.layout__statuspages .page__items.isLoading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff80;
}
/* ! RESPONSIVE DESIGN */
@media (max-width: 960px) {
  table.status-page-items td:nth-child(3) {
    flex-grow: 1;
    width: calc(100% - 100px);
    display: flex;
    justify-content: flex-end;
    color: #8c8c8c;
  }
}
