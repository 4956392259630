/* Intro */
.layout__intro {
  position: relative;
  display: flex;
  flex: 1;
}
.layout__intro .layout__intro--container {
  max-width: 800px;
  min-width: 600px;
  margin: 60px auto auto;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 40px;
  box-shadow:
    0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
    0 0 0 0.0625rem rgb(222 222 222 / 17%);
  background: white;
  display: flex;
  flex-direction: column;
}
.layout__intro .logo {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f7f7f7;
}
.layout__intro .logo svg {
  width: 200px;
}
.layout__intro .logo .nord-image {
}
.layout__intro h1 {
  font-size: 22px;
}
.layout__intro > p {
}
.layout__intro p.description {
  margin-top: 20px;
  font-size: 14px;
}
.layout__intro p.note {
  font-size: 12px;
  width: 100%;
  color: #9e9e9e;
}
