.modal-right {
}

.modal-right.active:before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000d1;
  position: fixed;
  z-index: 1000;
}
.modal-right .modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 9999999999999;
  -webkit-animation: 0.2s ease-out ss-slide-in-right;
  animation: 0.2s ease-out ss-slide-in-right;
  width: calc(100% - 40px);
  transform: translateX(+100%);
  -webkit-transform: translateX(+100%);
  transition: transform 100ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.22, 0.61, 0.36, 1)
    0ms;
}
.modal-right.active .modal-dialog {
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.modal-right .modal-header {
  padding: 20px;
  background-color: #ececec;
}
.modal-right button.close {
  position: absolute;
  top: 10px;
  left: -40px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.modal-right button.close svg {
}
.modal-right button.close svg * {
  fill: white;
}
.modal-right .modal-header h2 {
  font-size: 20px;
  font-weight: 400;
}
.modal-right .modal-body {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.modal-right .modal-body .modal_layout {
  flex-grow: 1;
}
.modal-right .modal-body .modal_layout-footer {
}
.modal-right .modal-content {
  box-shadow: 0 0;
  height: 100%;
  border-radius: 0;
  padding: 0px;
}
/* ! MODAL LIST LAYOUT FOR MONITORS */
.modal_layout-monitors-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.modal_layout-monitors-list .modal_list {
  flex-grow: 1;
}
.modal_layout-monitors-list .modal_list .modal-overflow-height_500 {
  max-height: initial;
}
.modal_layout-monitors-list .modal_list .m-monitor_item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 14px;
  margin-bottom: 7px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  cursor: pointer;
  background: #f5f5f5ab;
}
.modal_layout-monitors-list .modal_list .m-monitor_item:hover {
  background: #0368ff24;
}
.modal_layout-monitors-list .modal_list .m-monitor_item.selected {
  background: #0368ff24;
}
.modal_layout-monitors-list .modal_list .m-monitor_item input {
}
.modal_layout-monitors-list .modal_list .m-monitor_item .status-circle {
  width: 20px;
  height: 20px;
  order: -1;
  margin-right: 10px;
}
.modal_layout-monitors-list .modal_list .m-monitor_item .status-circle svg {
}
.modal_layout-monitors-list .modal_list .m-monitor_item .status-circle svg * {
}
.modal_layout-monitors-list .modal_list .m-monitor_item .chk_details-content {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
}
.modal_layout-monitors-list .modal_list .m-monitor_item .chk_details-name {
  width: 100%;
  color: #000000;
}
.modal_layout-monitors-list .modal_list .m-monitor_item .chk_details-url {
  font-size: 11px;
  color: #ff9800;
}
.modal_layout-monitors-list .modal_list .m-monitor_item.deactivated * {
  color: #919eaf !important;
  fill: #919eaf73 !important;
}

@media (min-width: 768px) {
  .modal-right .modal-dialog {
    width: 50%;
  }
}
