.incident_row--basic-info {
  display: flex;
  flex-wrap: wrap;
}
.incident_row--basic-info .incident_name {
  width: 100%;
  margin-bottom: 5px;
}
.incident_row--basic-info .incident_name a {
  color: #333;
}
.incident_row--basic-info .incident_last-event {
  font-size: 10px;
  background: #4e4e4e8a;
  color: #ffffff;
  line-height: 120%;
  padding: 2px 4px;
  border-radius: 3px;
}
.incident_row--basic-info .incident_last-event.investigating {
  background: #ff0c04;
  color: #ffffff;
}
.incident_row--basic-info .incident_last-event.identified {
  background: #fbca04;
  color: #000000;
}
.incident_row--basic-info .incident_last-event.monitoring {
  background: #0288d1;
  color: #ffffff;
}
.incident_row--basic-info .incident_last-event.resolved {
  background: #4caf50;
  color: #ffffff;
}
.incidents-items .incident_tabColumn-status {
  width: 60px;
}
.incidents-items .incident_tabColumn-info {
  width: calc(100% - 426px);
}
.incidents-items .incident_tabColumn-events {
  width: 140px;
}
.incidents-items .incident_tabColumn-date {
  width: 170px;
}
.incidents-items .incident_tabColumn-btn {
  width: 60px;
}

/* INCIDENT > FORM > NEW */
.event-progress-wrapper {
}
.event-progress-wrapper .outer-wrapper {
  position: relative;
  width: 100%;
  border-radius: 3px;
  background: #1853db0d;
  padding: 21px 0 15px !important;
}
.event-progress-wrapper #event-progress-bar {
  display: flex;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}
.event-progress-wrapper #event-progress-bar .padding {
  flex: 1;
  height: 8px;
}
.event-progress-wrapper #event-progress-bar .progress-bar {
  flex: 6;
  height: 8px;
  background: #dfe1e6;
  border-radius: 4px;
  overflow: hidden;
}
.event-progress-wrapper #event-progress-bar .progress-bar .progress {
  height: 100% !important;
  display: block;
  background-color: #0065ff;
  height: 100%;
  width: 0;
  transition:
    width 0.2s ease-in-out,
    height 0.2s ease-in-out;
  border-radius: 4px;
}
#event-progress-bar .progress.investigating {
}
#event-progress-bar .progress.identified {
  width: 33.333333% !important;
  background-color: #ffc107 !important;
}
#event-progress-bar .progress.monitoring {
  width: 66.666666666% !important;
}
#event-progress-bar .progress.resolved {
  width: 100% !important;
  background-color: #02d40a !important;
}
.event-progress-wrapper .events {
  display: flex;
  position: relative;
  z-index: 10;
}
.event-progress-wrapper .events .event-wrapper {
  flex: 1;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}
.event-progress-wrapper .events .event-wrapper .indicator {
}
.event-progress-wrapper .events .event-wrapper .indicator .dot {
  width: 8px;
  height: 8px;
  display: block;
  opacity: 0;
  margin: 0 auto 8px;
  border-radius: 20px;
  background-color: #dfe1e6;
  box-sizing: content-box;
  box-shadow: 0 0 6px 1px #a7a7a7;
}
.event-progress-wrapper .events .event-wrapper .indicator .dot.active {
}
.event-progress-wrapper .events .event-wrapper .indicator .dot.selected {
}
.event-progress-wrapper .events .event-wrapper .label {
  font-size: 12px;
  line-height: 16px;
}
.event-progress-wrapper .events .event-wrapper .label.active {
  font-weight: 600;
}
.event-progress-wrapper .events .event-wrapper .label.selected {
  color: #1853db;
}
.event-progress-wrapper .events .event-wrapper .label span {
}
.affected_monitors-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.affected_monitors-item {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.affected_monitors-item.is-active:before {
  background: #06dc06;
}
.affected_monitors-item.is-down:before {
  background: red;
}
.affected_monitors-item .am_item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin-left: 10px;
  flex-grow: 1;
}
.affected_monitors-item .am_item--name {
  flex-grow: 1;
}
.affected_monitors-item .am_item--status {
  margin-right: 10px;
}
.affected_monitors-item .am_item--status .textField {
  font-size: 13px;
}
.affected_monitors-item .am_item--btn_actions {
}
.affected_monitors-item .am_item--btn_actions .btn_action-item {
  cursor: pointer;
}
.affected_monitors-item .am_item--btn_actions .btn_action-item.remove svg * {
  fill: red;
}
.affected-monitors-wrapper .no-items-selected {
  padding: 20px;
  border: 2px dashed #ccc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #bbb;
}
.affected-monitors-wrapper .no-items-selected.error {
  border-color: #ff00009e;
  color: #ff0000e3;
}
.affected-monitors-wrapper .no-items-selected .no-items-selected_inner {
}
/** EDIT FORM */
.incident-form-edit {
}
.incident-form-edit .layout__body {
  flex-wrap: nowrap;
  align-items: flex-start;
}
.incident-form-edit .layout__historyItems {
  width: 45%;
  margin-right: 30px;
}
.incident-form-edit .layout__historyItems .no-items {
  padding: 20px;
  color: #b9b9b9;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.incident-form-edit .layout__historyItems label {
  font-size: 14px;
  font-weight: 600;
  color: #f64900;
  border-bottom: 1px solid #f649003b;
  padding-bottom: 5px;
  margin-bottom: 5px;
  display: flex;
}
.incident-form-edit .layout__body form {
  flex-grow: 1;
}
.incident-form-edit .historyItems--wrapper {
  margin-top: -6px;
}
.incident-form-edit .historyItems--wrapper ul {
  list-style: none;
}
.incident-form-edit .historyItems--wrapper ul li {
  display: flex;
}
.incident-form-edit .historyEvents--timeline {
  flex: 0 0 30px;
  align-self: stretch;
  position: relative;
  text-align: center;
}
.incident-form-edit .historyEvents--timeline:before {
  content: '';
  position: absolute;
  left: calc(50% - 1px);
  z-index: 0;
  width: 2px;
  height: 100%;
  background-color: #f6490069;
}
.historyEvents--timeline .timeline-icon {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-top: 14px;
}
.historyEvents--timeline .timeline-icon .status-icon {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #f6490085;
  border-radius: 100%;
  background: #ffffff no-repeat 50% 50%;
  background-size: 100% 100%;
  text-indent: -9999px;
  width: 10px;
  height: 10px;
}
.incident-form-edit .historyEvents--item {
  padding: 16px 10px;
  flex-grow: 1;
}
.incident-form-edit .historyEvents--item .msg {
  font-size: 12px;
}
.incident-form-edit .historyEvents--item .outline-info {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #ababab;
  margin-top: 6px;
}
.incident-form-edit .historyEvents--item .outline-info span {
  display: flex;
  margin-right: 4px;
  line-height: 100%;
}
.incident-form-edit .historyEvents--item .outline-info .outline_info-date {
}
.incident-form-edit
  .historyEvents--item
  .outline-info
  .outline_info-event_type {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  padding: 3px 4px;
  border-radius: 2px;
  background: #607d8b;
  color: white;
}
.historyEvents--item .outline-info .outline_actions {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.historyEvents--item
  .outline-info
  .outline_actions
  *[class^='outline_actions--'] {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  cursor: pointer;
}
.historyEvents--item
  .outline-info
  .outline_actions
  *[class^='outline_actions--']
  svg {
  width: 100%;
  height: 100%;
}
.historyEvents--item
  .outline-info
  .outline_actions
  .outline_actions--edit
  svg
  * {
  fill: #030956;
}
.historyEvents--item
  .outline-info
  .outline_actions
  .outline_actions--delete
  svg
  * {
  fill: red;
}
.historyEvents--item .outline_info-event_type.investigating {
  background: #ff0c04;
  color: #ffffff;
}
.historyEvents--item .outline_info-event_type.identified {
  background: #fbca04;
  color: #000000;
}
.historyEvents--item .outline_info-event_type.monitoring {
  background: #0288d1;
  color: #ffffff;
}
.historyEvents--item .outline_info-event_type.resolved {
  background: #4caf50;
  color: #ffffff;
}
/* ! Events History >  affected monitors > Tooltip */
.tooltip--events-item_affected-monitors {
}
.tooltip--events-item_affected-monitors h3 {
  color: white;
  font-weight: 600;
  border-bottom: 1px solid #242f34;
  padding-bottom: 5px;
}
.tooltip--events-item_affected-monitors ul {
}
.tooltip--events-item_affected-monitors ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
}
.tooltip--events-item_affected-monitors ul li label {
}
.tooltip--events-item_affected-monitors ul li span.icon {
  width: 20px;
  height: 20px;
  display: flex;
  background-size: 100% !important;
  margin-right: 10px;
  background-repeat: no-repeat !important;
  flex: 0 0 20px;
}
.tooltip--events-item_affected-monitors ul li span.icon.OPERATIONAL {
  background: url(../../../assets/icons/resolved.png);
  background-position: 0px -3px;
}
.tooltip--events-item_affected-monitors ul li span.icon.DEGRADED-PERFORMANCE {
  background: url(../../../assets/icons/degraded-performance.png);
  background-position: 0px -3px;
}
.tooltip--events-item_affected-monitors ul li span.icon.PARTIAL-OUTAGE {
  background: url(../../../assets/icons/partial-outage.png);
}
.tooltip--events-item_affected-monitors ul li span.icon.MAJOR-OUTAGE {
  background: url(../../../assets/icons/major-outage.png);
  background-position: 0 -3px;
}
.tooltip--events-item_affected-monitors ul li span.icon.UNDER-MAINTENANCE {
  background: url(../../../assets/icons/under-maintenance.png);
  background-position: 0px -3px;
}
/** RESPONSIVE DESIGN */
@media (max-width: 767px) {
  .incidents-items .incident_tabColumn-info {
    width: calc(100% - 60px);
  }
  .incidents-items .incident_tabColumn-events {
    text-align: left;
  }
  .incidents-items .incident_tabColumn-date {
    width: calc(100% - 200px);
  }
}
