.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
}
.modal:before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000a6;
  position: fixed;
  z-index: 1000;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  z-index: 9999;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px;
    margin: 30px auto;
  }
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition:
    transform 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
  -webkit-transform: translateY(-25%);
  transform: translateY(-25%);
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0);
  transform: translate(0);
}
.modal.small-window .modal-dialog {
  max-width: 500px;
}
.modal-dialog .modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  border-radius: 6px;
  padding: 16px 16px 10px;
  -webkit-box-shadow:
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow:
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
}
.modal-dialog .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px 22px;
  border-bottom: 0 solid #e0e6ed;
}
.modal-dialog .modal-header h2 {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 16px;
  margin: 0;
}
.modal-dialog .modal-header button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  outline: none;
}
.modal-dialog .modal-header button.close span {
}
.modal-dialog .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 16px 22px 12px 22px;
  background-color: #fff;
}
.modal-dialog .modal-response {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
.modal-dialog .modal-body .modal-body_content-rapper {
}
.modal-dialog .modal-body .switching-accounts_title {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #777;
}
.modal-dialog .modal-overflow-height_500 {
  max-height: 500px;
  overflow-y: auto;
}
.modal-dialog .note {
  font-size: 12px;
}
