.layout__monitors {
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.layout__monitors .check__items {
  margin: 20px 0px;
  width: 100%;
  position: relative;
  flex-grow: 1;
}

.layout__monitors .check__items.isLoading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff80;
}
.layout__monitors .check__items .getstarted-now__addMonitor {
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout__monitors .check__items .getstarted-now__addMonitor .btn {
  text-transform: uppercase;
  padding: 14px 18px;
  font-size: 11px;
  font-weight: 600;
  margin-top: 10px;
  color: #5c5c5c;
}
.layout__monitors tr th {
  padding: 10px 8px;
  font-size: 11px;
  color: #7d7d7d;
  text-transform: uppercase;
  font-weight: 400;
}
.layout__monitors .check__item {
}
.layout__monitors .check__item:hover {
  background-color: #f9fafc;
  cursor: pointer;
}
.layout__monitors .check__item.deactivated {
  position: relative;
}
.layout__monitors .check__item.deactivated * {
  color: #919eaf !important;
  fill: #919eaf73 !important;
}
.layout__monitors .check__item > td {
  border-top: 1px solid #eff2f7;
  padding: 4px 8px;
  vertical-align: middle;
  color: #3c4858;
  max-width: 300px;
}
.layout__monitors .check__item > td:first-child {
  width: 30px;
}
.layout__monitors .check__item .check__item--wrapper {
  display: flex;
}
.layout__monitors .check__item .check__info .chk_details-content {
  display: flex;
  flex-wrap: wrap;
}
.layout__monitors .check__item .check__info > a {
  text-transform: capitalize;
}
.layout__monitors .check__item .check__info .chk_details-name {
  font-weight: 500;
  font-size: 14px;
  color: #626262;
  width: 100%;
  text-transform: capitalize;
  padding-bottom: 4px;
}
.layout__monitors .check__item .check__info .chk_details-date {
  color: #8492a6;
  font-size: 12px;
  position: relative;
  margin-right: 28px;
  display: none;
}
.layout__monitors .check__item .check__info .chk_details-url {
  color: #969696;
  font-size: 12px;
  position: relative;
  display: flex;
  align-items: center;
}
.layout__monitors .check__item .check__info .chk_details-url:before {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: auto;
  left: auto;
  font-weight: 400;
  margin-left: -1rem;
  content: '•';
  opacity: 1;
  color: inherit;
  vertical-align: top;
  display: none;
}
.layout__monitors
  .check__item
  .check__info
  .chk_details-url
  .chk-d__ssl-status {
  width: 12px;
  height: 12px;
  display: flex;
  margin-right: 6px;
  padding: 0px;
  border-radius: 2px;
}
.layout__monitors
  .check__item
  .check__info
  .chk_details-url
  .chk-d__ssl-status
  svg {
  width: 100%;
  height: 100%;
}
.layout__monitors .chk-d__full-url {
  color: #607d8b;
}
.layout__monitors .ghost-link {
  padding: 0.5em;
  display: block;
}
.chk-d__ssl-status.secure-ssl svg {
  fill: #07dd10;
}
.chk-d__ssl-status.unsecure-ssl svg {
  fill: #ff5722;
}

.layout__monitors .check__item .check__url {
  flex-grow: 1;
  font-size: 12px;
}
.layout__monitors .check__item .check__url > a {
}
.layout__monitors .check__item .check__globalInsight {
  width: 120px;
}
.layout__monitors .check__item .check__globalInsight span.globalInsight--num {
  font-size: 13px;
  color: #333;
}
.layout__monitors .check__item .check__globalInsight span.globalInsight--label {
  display: none;
}
.layout__monitors .check__item .check__graph {
  width: 210px;
}
.check__graph .loading-wrapper {
}
.check__graph .loading-wrapper .spinner {
  height: 0px !important;
}
.check__item .check__graph .status-bar-graph {
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* padding: 20px 0; */
}
.check__item .check__graph .status-bar-graph ul {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
}
.check__item .check__graph .status-bar-graph ul li.status-bar-graph-item {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 8px;
  vertical-align: bottom;
  z-index: 1;
  min-height: 2px !important;
  background: #ddd;
  max-height: 40px;
}
.check__item
  .check__graph
  .status-bar-graph
  ul
  li.status-bar-graph-item.deactivated,
.check__item.deactivated
  .check__graph
  .status-bar-graph
  ul
  li.status-bar-graph-item {
  background: #bdbdbd !important;
}
.check__item
  .check__graph
  .status-bar-graph
  ul
  li.status-bar-graph-item.success {
  background: #52de57;
}
.check__item .check__graph .status-bar-graph ul li.status-bar-graph-item.fail {
  background: #f44336;
}
.check__item
  .check__graph
  .status-bar-graph
  ul
  li.status-bar-graph-item.warning {
  background: #ffdd46;
}
/* ! RESPONSIVE DESGIN */
@media (max-width: 1024px) {
  .appContainer.logged .mainContainer {
    padding-left: 0 !important;
    width: 100% !important;
  }
}
@media (max-width: 960px) {
  .layout__monitors table.check__items thead {
    display: none;
  }
  .layout__monitors tr.check__item {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 3px;
    box-shadow:
      0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
      0 0 0 0.0625rem rgb(222 222 222 / 17%);
  }
  .layout__monitors tr.check__item td {
    /* width: auto!important; */
    border: 0;
  }
  .layout__monitors tr.check__item td:nth-child(1) {
    width: 50px;
    display: flex;
    align-items: center;
  }
  .layout__monitors tr.check__item td:nth-child(2) {
    width: calc(100% - 50px);
  }
  .layout__monitors tr.check__item td:nth-child(3),
  .layout__monitors tr.check__item td:nth-child(4),
  .layout__monitors tr.check__item td:nth-child(5),
  .layout__monitors tr.check__item td:nth-child(6) {
    width: 25%;
  }
  .layout__monitors tr.check__item td:nth-child(7) {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .check__item .check__graph .status-bar-graph ul li.status-bar-graph-item {
    width: calc(100% / 24);
  }
}
