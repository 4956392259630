.custom-tooltip {
  background-color: #0b0a33;
  opacity: 0.85;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  width: 260px;
  line-height: 1.5em;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.custom-tooltip.responseTime__chart-tooltip {
  flex-direction: column;
}
.custom-tooltip.responseTime__chart-tooltip .label {
  font-weight: 300;
  font-size: 11px;
}
.custom-tooltip .sub-label {
  font-size: 11px;
  font-weight: normal;
}
.custom-tooltip > .label {
  display: flex;
  flex-direction: column;
}

.custom-tooltip.responseTime__chart-tooltip .main-item {
  display: flex;
  justify-content: space-between;

  align-items: center;
}
.custom-tooltip.responseTime__chart-tooltip .main-item .lineColor {
  width: 10px;
  height: 10px;
  border-radius: 1px;
  margin-right: 10px;
}
.custom-tooltip.responseTime__chart-tooltip .main-item .label {
  flex-grow: 1;
}

.custom-tooltip.responseTime__chart-tooltip .secondary-item {
  border-top: 1px solid #ffffff4a;
  margin-top: 4px;
  padding-top: 4px;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul {
  display: flex;
  flex-direction: column;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  align-items: center;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li .lineColor {
  width: 10px;
  height: 10px;
  border-radius: 1px;
  margin-right: 10px;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li .label {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li .value {
}
