body {
  background: #f8f8f8;
}
.layout__session.layout__form--login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.layout__form--login .layout__session--container {
  width: 420px;
  background: white;
  padding: 24px;
  box-shadow:
    0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.layout__form--login .layout__session--container .logo {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 220px;
  align-self: center;
  margin-bottom: 0;
}
.layout__form--login .layout__session--container .logo > svg {
  width: 100%;
  height: 100%;
}
.layout__form--login .layout__session--container h1 {
  text-align: center;
  font-weight: 600;
  margin: 0;
  margin-top: 40px;
  font-size: 24px;
}
.layout__form--login .layout__session--container form {
  display: flex;
  justify-content: center;
}
.layout__form--login .layout__session--container form .form-container {
  width: 100%;
}
.layout__form--login
  .layout__session--container
  form
  .form-container
  .response-group {
}
.layout__form--login
  .layout__session--container
  form
  .form-container
  .response-group
  div {
  font-size: 13px;
}
.layout__form--login
  .layout__session--container
  form
  .form-container
  .field-group {
}
.layout__form--login
  .layout__session--container
  form
  .form-container
  .field-group
  label {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}
.layout__form--login
  .layout__session--container
  form
  .form-container
  .field-group.field-checkbox
  label {
  text-transform: initial;
  font-size: 13px;
}
.layout__form--login
  .layout__session--container
  form
  .form-container
  .field-group.field-checkbox
  label
  > a {
  padding: 0 4px;
}
.layout__form--login
  .layout__session--container
  form
  .form-container
  .field-group
  .textField {
  font-size: 16px;
}
.layout__form--login .layout__session--container .other-sign-block {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
}
.layout__form--login .layout__session--container .other-sign-block label {
  color: #8a8c91;
  margin-right: 6px;
}
.layout__form--login .layout__session--container .other-sign-block a {
  font-weight: 600;
}
