button {
  color: white;
  background-color: blue;
}
.btn-upgrade-wrapper {
  margin-left: 10px;
}
.btn.btn-upgrade-feature {
  background: #ff9802;
  color: white;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.btn.btn-upgrade-feature:hover {
  background: #ff8100;
}
