.layout__monitor--details {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}
.layout__monitor--details .alert {
  padding: 16px 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background: #56ff6966;
  color: #00ad07;
  width: 100%;
  border: 1px solid transparent;
}
.layout__monitor--details .details__card {
  flex: 0 0 33.33333333%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 33.33333333%;
}
.layout__monitor--details .details__card.col-full {
  width: 100%;
  flex: 0 0 100%;
}
.layout__monitor--details .details__card .details__card-wrapper {
  border-radius: 4px;
  padding: 0;
  box-shadow:
    0 0.125rem 0.1875rem 0 rgba(18, 18, 18, 0.1),
    0 0 0 0.0625rem rgba(18, 18, 18, 0.1);
  box-shadow:
    0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
    0 0 0 0.0625rem rgb(222 222 222 / 17%);
  min-height: 200px;
  background: white;

  width: 100%;
  /* overflow: hidden;
	overflow-x: auto; */
}
.layout__monitor--details .details__card.filters {
  flex: 1 1 100%;
  justify-content: flex-end;
}
.layout__monitor--details .details__card.filters .filter-item {
}
.layout__monitor--details .details__card.insightMonitor__responseTime {
  flex-basis: 100%;
  width: 100%;
}
.details__card-wrapper .recharts-responsive-container.no-data {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.details__card-wrapper .recharts-responsive-container.no-data .no-data-alert {
  font-size: 10px;
  text-transform: uppercase;
}
.details__card-wrapper.log__container {
  display: flex;
  flex-wrap: wrap;
}
.details__card-wrapper.log__container .log__column {
  flex: 0 0 100%;
}
.details__card-wrapper.log__container .log__column.error-column {
  border-right: 1px solid #f5f5f5;
  flex-basis: 35%;
  display: none;
}

.details__card-wrapper.log__container .log__footer {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #f4f4f4;
  margin-top: 10px;
}
.details__card-wrapper.log__container .log__footer .pagi__button {
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.details__card-wrapper.log__container .log__footer .pagi__button.p_btn-prev {
  background: #e4e4e4
    url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDQzLjUyIDQ0My41MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQzLjUyIDQ0My41MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGc+DQoJCTxwYXRoIGQ9Ik0xNDMuNDkyLDIyMS44NjNMMzM2LjIyNiwyOS4xMjljNi42NjMtNi42NjQsNi42NjMtMTcuNDY4LDAtMjQuMTMyYy02LjY2NS02LjY2Mi0xNy40NjgtNi42NjItMjQuMTMyLDBsLTIwNC44LDIwNC44DQoJCQljLTYuNjYyLDYuNjY0LTYuNjYyLDE3LjQ2OCwwLDI0LjEzMmwyMDQuOCwyMDQuOGM2Ljc4LDYuNTQ4LDE3LjU4NCw2LjM2LDI0LjEzMi0wLjQyYzYuMzg3LTYuNjE0LDYuMzg3LTE3LjA5OSwwLTIzLjcxMg0KCQkJTDE0My40OTIsMjIxLjg2M3oiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 4px 0 0 4px;
}
.details__card-wrapper.log__container .log__footer .pagi__button.p_btn-next {
  background: #e4e4e4
    url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDQzLjUyIDQ0My41MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQzLjUyIDQ0My41MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGc+DQoJCTxwYXRoIGQ9Ik0zMzYuMjI2LDIwOS41OTFsLTIwNC44LTIwNC44Yy02Ljc4LTYuNTQ4LTE3LjU4NC02LjM2LTI0LjEzMiwwLjQyYy02LjM4OCw2LjYxNC02LjM4OCwxNy4wOTksMCwyMy43MTJsMTkyLjczNCwxOTIuNzM0DQoJCQlMMTA3LjI5NCw0MTQuMzkxYy02LjY2Myw2LjY2NC02LjY2MywxNy40NjgsMCwyNC4xMzJjNi42NjUsNi42NjMsMTcuNDY4LDYuNjYzLDI0LjEzMiwwbDIwNC44LTIwNC44DQoJCQlDMzQyLjg4OSwyMjcuMDU4LDM0Mi44ODksMjE2LjI1NSwzMzYuMjI2LDIwOS41OTF6Ii8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 0 4px 4px 0;
  margin-left: 1px;
}
.details__card .details__card-wrapper h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  padding: 20px;
}
.details__card .details__card-wrapper h2 span {
}
.details__card .details__card-wrapper h2 i {
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 11px;
  margin-left: 10px;
}
.details__card .details__card-wrapper .log__content {
}
.details__card .details__card-wrapper .log__content .alert {
  margin: 20px;
}
.layout__monitor--details .details__card--wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.layout__monitor--details .layout__keys--data {
  width: 100%;
}
.layout__monitor--details .layout__keys--data ul {
  list-style: none;
  display: flex;
}
.layout__monitor--details .layout__keys--data ul li {
  padding: 12px;
  flex: 1 0 20%;
}
.layout__monitor--details .layout__keys--data ul li .li__wrapper {
  box-shadow:
    0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
    0 0 0 0.0625rem rgb(222 222 222 / 17%);
  padding: 16px 16px;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: white;
}
.layout__monitor--details .layout__keys--data ul li .label {
  font-size: 13px;
  color: #777;
}
.layout__monitor--details .layout__keys--data ul li .span {
  margin-top: 6px;
  font-weight: 600;
  font-size: 18px;
  color: #333;
}
.layout__monitor--details
  .layout__keys--data
  ul
  li
  .li__wrapper.check-ssl-stats {
  flex-direction: initial;
  position: relative;
  align-items: baseline;
  padding: 15px 15px;
}
.layout__monitor--details
  .layout__keys--data
  ul
  li
  .li__wrapper.check-ssl-stats
  .label {
  width: 100%;
}
.layout__monitor--details
  .layout__keys--data
  ul
  li
  .li__wrapper.check-ssl-stats
  .icon {
  position: absolute;
  right: 10px;
}
.layout__monitor--details
  .layout__keys--data
  ul
  li
  .li__wrapper.check-ssl-stats
  .icon
  svg {
  width: 18px;
  height: 18px;
}
.layout__monitor--details
  .layout__keys--data
  ul
  li
  .li__wrapper.check-ssl-stats
  .span {
  flex-grow: 1;

  text-align: end;
}
.layout__monitor--details .check-ssl-stats.check-stats-ssl-box-success {
  color: #13ce66 !important;
  background: #edfdf4 !important;
  border: 1px solid #13ce6645 !important;
}
.layout__monitor--details .check-ssl-stats.check-stats-ssl-box-success * {
  color: #13ce66 !important;
  fill: #13ce66 !important;
}
.layout__monitor--details .check-ssl-stats.check-stats-ssl-box-warning {
  border: 1px solid #ffc82c4a !important;
  color: #ffc82c !important;
  background: #fff7df !important;
}
.layout__monitor--details .check-ssl-stats.check-stats-ssl-box-warning * {
  color: #ffc82c !important;
  fill: #ffc82c !important;
}
.layout__monitor--details .check-ssl-stats.check-stats-ssl-box-danger {
  border: 1px solid #ff49491f !important;
  color: #ff4949 !important;
  background: #ffecec !important;
}
.layout__monitor--details .check-ssl-stats.check-stats-ssl-box-danger * {
  color: #ff4949 !important;
  fill: #ff4949 !important;
}
.layout__monitor--details .layout__header {
  padding: 20px 20px 0px 20px;
}
.check-ssl-stats .readmore {
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.check-ssl-stats .readmore:hover {
  text-decoration: underline;
}
.ssl-certificate-info-wrapper {
}
.ssl-certificate-info-wrapper ul {
}
.ssl-certificate-info-wrapper ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.ssl-certificate-info-wrapper ul li label {
  margin-right: 10px;
  font-size: 13px;
  color: #03a9f4;
  width: 100%;
}
.ssl-certificate-info-wrapper ul li span {
  font-size: 13px;
}
.layout__monitor--details .layout__header {
}
.layout__monitor--details .layout-header__bottom_menu {
  width: 100%;
  margin-top: 20px;
}
.layout__monitor--details .layout-header__bottom_menu ul {
  list-style: none;
  display: flex;
  margin-bottom: -4px;
}
.layout__monitor--details .layout-header__bottom_menu ul li {
  display: flex;
}
.layout__monitor--details .layout-header__bottom_menu ul li:hover a {
  color: #1a53db;
}
.layout__monitor--details .layout-header__bottom_menu ul li.active {
}
.layout__monitor--details .layout-header__bottom_menu ul li a {
  padding: 10px 20px 14px 20px;
  color: #a2a2a2;
}
.layout__monitor--details .layout-header__bottom_menu ul li.active a {
  background: #f4f6f8;
  border: 1px solid #efefef;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  color: #3578e5;
  font-weight: 600;
}

/* log content  */
.log__items--content {
  position: relative;
  padding: 0px 20px 20px 20px;
}
.log__items--content:before {
  content: ' ';
  background: #e6e6e6;
  display: inline-block;
  position: absolute;
  left: 37px;
  width: 1px;
  height: 100%;
  z-index: 0;
}
.log__item {
  position: relative;
  padding-left: 60px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.outage-column .log__item {
  padding-top: 14px;

  padding-bottom: 14px;
}
.log__item.failed_logs:before {
  content: '';
  background-image: url(./../../../assets/icons/fail.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  margin-top: 8px;
  left: 0;
  width: 36px;
  height: 36px;
  z-index: 400;
}
.log__item.recovered_logs:before {
  content: '';
  background-image: url(./../../../assets/icons/success.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  margin-top: 8px;
  left: 0;
  width: 36px;
  height: 36px;
  z-index: 400;
}
.log__item .log__item--wrapper {
  padding-bottom: 14px;
}
.log__item .log__item--wrapper .log__item--baseline {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.log__item .log__item--wrapper .log__item--baseline .chk-name {
  margin-right: 8px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: #666;
}
.log__item .log__item--wrapper .log__item--baseline .chk-state--description {
  color: #8492a6;
  margin-right: 8px;
}
.log__item .log__item--wrapper .log__item--baseline .chk-location {
  color: #8492a6;
}
.log__item .log__item--wrapper .log__item--baseline .chk-location img {
  width: 18px !important;
}
.log__item .log__item--wrapper .log__item--baseline .chk-location .flag-icon {
}
.log__item .log__item--wrapper .log__item--baseline .chk-location span {
  margin-left: 3px;
}
.log__item .log__item--wrapper .log__item--baseline .chk-outageDuration {
  font-size: 12px;
  color: #3a3a3a;
  flex-grow: 1;
  text-align: right;
}
.log__item .log__item--wrapper .log__item--pinfo {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 4px;
}
.log__item .log__item--wrapper .log__item--pinfo .chk-statusCode {
  background: #878787;
  margin-right: 11px;
  color: #fff;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 2px;
  line-height: 120%;
}
.log__item .chk-statusCode.failedCheck {
  background: #fe4a49 !important;
}
.log__item .chk-statusCode.recoveredCheck {
  background: #0ace66 !important;
}
.log__item.failed_logs .chk-statusCode {
  /* background: #ff11008a !important; */
}
.log__item .log__item--wrapper .log__item--pinfo .chk-responseTime {
  color: #777;
  margin-right: 16px;
}
.log__item .log__item--wrapper .log__item--pinfo .chk-time {
  color: #777;
}
.log__item .log__item--pinfo .seperator {
  margin: 0 10px;
}

/** LAYOUT > HEADER */
.layout__header .monitor__info {
  flex: 1 1;
}
.layout__header .monitor__info .monitor__name {
  display: flex;
  align-items: center;
  padding: 5px 0 0 5px;
}
.layout__header .monitor__info .monitor__name h2 {
  margin: 0;
  text-transform: capitalize;
  font-size: 16px;
  margin-left: 8px;
}
.layout__header .monitor__info .monitor__name .monitor__runlocations {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.layout__header .monitor__info .monitor__name .monitor__runlocations img {
  margin-left: 4px;
  width: 20px !important;
  height: 17px !important;
  margin: 0 4px;
  border-radius: 3px;
}
.layout__header .monitor__info .monitor__request {
  display: flex;
  font-size: 13px;
  color: #797979;
  margin-top: 7px;
}
.layout__header .monitor__info .monitor__request .request_method {
  margin-right: 10px;

  background: #fff3e0;
  padding: 0px 4px;
  color: #ef6c00;
  border-radius: 2px;
  line-height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.layout__header .monitor__info .monitor__request .request_url {
  color: #9e9e9e;
}
.layout__header .monitor__btnAction {
  display: flex;
  align-items: center;
  max-height: 35px;
}
.layout__header .monitor__btnAction > .btn {
  margin-left: 10px;
}
.layout__header .monitor__btnAction > .btn a {
  padding: 10px 18px;
}
/** RECHART DETAILS */
.recharts-responsive-container .customized-legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.recharts-responsive-container .customized-legend.right {
  justify-content: flex-end;
}
.recharts-responsive-container .customized-legend.left {
  justify-content: flex-start;
}
.recharts-responsive-container .customized-legend .legend-item {
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  margin-bottom: 2px;
}
.recharts-responsive-container .customized-legend .legend-item.inactive {
  color: #aaa !important;
}
.recharts-responsive-container .customized-legend .legend-item > svg {
}
.recharts-responsive-container .customized-legend .legend-item > span {
  margin-left: 5px;
}

/** Monitor Details > Results Log */
.results-log-table {
}
.results-log-table .r-log_status {
  background-image: url(./../../../assets/icons/success.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 26px;
  height: 19px;
  display: flex;
}
.results-log-table .r-log_status.down {
  background-image: url(./../../../assets/icons/fail.svg);
}
.results-log-table .r-log_statuscode {
  font-size: 12px;
  background: #03d203;
  color: white;
  border-radius: 2px;
  padding: 1px 4px;
}
.results-log-table .r-log_statuscode.down {
  background: #f44336;
}
.results-log-table .responsetime {
  display: flex;
  align-items: center;
}
.results-log-table .responsetime > .icon {
  display: flex;
  margin-right: 10px;
}
.results-log-table .responsetime > .icon svg {
}
.results-log-table .responsetime > .icon svg * {
  fill: #8c8c8c;
}
.results-log-table .responsetime > span {
}
.results-log-table .r-log_timing {
}
.results-log-table .chk-location {
  font-size: 13px;
  color: #333;
}
.results-log-table .chk-location img {
  width: 20px !important;
  height: auto !important;
  margin-right: 10px;
}

/* ! RESPONSIVE DESGIN */
@media (max-width: 960px) {
  .layout__monitor--details .details__card {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    flex-basis: 100%;
  }
  .layout__monitor--details .layout__keys--data ul {
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
  }
  .layout__monitor--details .layout__keys--data ul li {
    flex-basis: 33.33333333%;
  }
  .results-log-table tbody tr td:nth-child(4) {
    flex-grow: 1;
  }
  .results-log-table tbody tr td:nth-child(6) {
    width: 170px;
  }
  .layout__header .monitor__btnAction {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 10px;
    order: -1;
    padding-bottom: 10px;
  }
}
@media (max-width: 425px) {
  .layout__monitor--details .layout__keys--data ul li {
    flex-basis: 50%;
  }
  .log__item .log__item--wrapper .log__item--pinfo {
    flex-wrap: wrap;
  }
  .log__item .log__item--wrapper .log__item--pinfo > * {
    margin-bottom: 4px;
  }
}
