@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  position: relative;
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 30%;
  left: 30%;
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #2196f3;
  animation: spinner 0.6s linear infinite;
}
.spinner.small:before {
  width: 18px;
  height: 18px;
  top: calc(50% - 9px);
  margin-top: 0px;
  left: calc(50% - 9px);
  margin-left: 0px;
}
