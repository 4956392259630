.layout__monitor--form {
}
.layout__monitor--form .form__content {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 50%;
}
.layout__monitor--form .form__content.col-full {
  width: 100%;
  flex: 0 0 100%;
}
.layout__monitor--form .form__content .form__content-wrapper {
  border-radius: 4px;
  padding: 0;
  /* box-shadow: 0 0.125rem 0.1875rem 0 rgba(18,18,18,.1), 0 0 0 0.0625rem rgba(18,18,18,.1); */
  min-height: 200px;
  background: white;
  padding: 20px;
  width: 100%;
  overflow-x: auto;
}
.layout__monitor--form .form__content-wrapper header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}
.layout__monitor--form .form__content-wrapper header h1 {
  font-size: 16px;
  margin: 0;
}
.layout__monitor--form .form__content-wrapper form {
  display: flex;
  flex-direction: column;
}
.layout__monitor--form .field-group {
}
.layout__monitor--form .field-group label {
  font-size: 13px;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
}
.layout__monitor--form .field-group .textField {
  padding: 0.5rem;
}
.layout__monitor--form .field-group .field-group__multipleElement {
  display: flex;
  flex-wrap: wrap;
}
.layout__monitor--form .field-group .field-group__multipleElement select {
  margin-right: 10px;
}
.layout__monitor--form .field-group .field-group__multipleElement input {
  flex-grow: 1;
}
.layout__monitor--form
  .field-group
  .field-group__multipleElement
  .fieldText_alert--error {
  flex-basis: 100%;
  width: 100%;
}
.fieldText_alert--error {
  color: red;
}
.layout__monitor--form .field-group .checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.monitor-regions {
}
.monitor-regions ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.monitor-regions ul li {
  flex: 0 0 33.33333333%;
  padding: 6px 18px;
}
.monitor-regions ul li label {
  display: flex;
  border-radius: 3px;
  border: 1px solid #dedede;
  padding: 12px !important;
  align-items: stretch;
  margin-bottom: 0 !important;
}
.monitor-regions ul li label.checked {
  border-color: #1a53db7a;

  background: #2196f32b;
}
.monitor-regions ul li label:hover {
  background: #f5f5f5;
}
.monitor-regions ul li label > input[type='checkbox'] {
  display: none;
}
.monitor-regions ul li label > img {
  width: 40px !important;
  height: 30px !important;
}
.monitor-regions ul li label > .region-details {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 8px;
}
.monitor-regions ul li label > .region-details .region-name {
  width: 100%;
  font-size: 12px;
  color: #4e4e4e;
  font-weight: 600;
  text-transform: uppercase;
}
.monitor-regions ul li label > .region-details .region-id {
}
.layout__monitor--form .field-group .radio-group {
  display: flex;
  align-items: center;
}
.layout__monitor--form .field-group .radio-group > label {
  align-items: center;
  font-weight: normal;
}
.tab-group {
}
.tab-group .tab_header {
  padding: 10px 0;
}
.tab-group .tab_header ul {
  list-style: none;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}
.tab-group .tab_header ul li {
  margin-right: 10px;
}
.tab-group .tab_header ul li .tab_header-item {
  padding: 6px 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
}
.tab-group .tab_header ul li .tab_header-item.selected {
  border-bottom: 2px solid #4587ff;
}
.tab-group .tab_content {
}
.tab-group .tab_content ul {
  list-style: none;
}
.tab-group .tab_content ul li {
  display: none;
}
.tab-group .tab_content ul li.selected {
  display: block;
}
.multiple-items_lines {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.multiple-items_lines .httpHeader--line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.multiple-items_lines .httpHeader--line .field-group {
  flex-grow: 1;
  padding-top: 0;
  padding-bottom: 0;
}
.multiple-items_lines .httpHeader--line .field-group .textField {
}
.multiple-items_lines .httpHeader--line .httpHeader--line_remove {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDA5LjgwNiA0MDkuODA2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MDkuODA2IDQwOS44MDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBkPSJNMjI4LjkyOSwyMDUuMDFMNDA0LjU5NiwyOS4zNDNjNi43OC02LjU0OCw2Ljk2OC0xNy4zNTIsMC40Mi0yNC4xMzJjLTYuNTQ4LTYuNzgtMTcuMzUyLTYuOTY4LTI0LjEzMi0wLjQyDQoJCQljLTAuMTQyLDAuMTM3LTAuMjgyLDAuMjc3LTAuNDIsMC40MkwyMDQuNzk2LDE4MC44NzhMMjkuMTI5LDUuMjFjLTYuNzgtNi41NDgtMTcuNTg0LTYuMzYtMjQuMTMyLDAuNDINCgkJCWMtNi4zODgsNi42MTQtNi4zODgsMTcuMDk5LDAsMjMuNzEzTDE4MC42NjQsMjA1LjAxTDQuOTk3LDM4MC42NzdjLTYuNjYzLDYuNjY0LTYuNjYzLDE3LjQ2OCwwLDI0LjEzMg0KCQkJYzYuNjY0LDYuNjYyLDE3LjQ2OCw2LjY2MiwyNC4xMzIsMGwxNzUuNjY3LTE3NS42NjdsMTc1LjY2NywxNzUuNjY3YzYuNzgsNi41NDgsMTcuNTg0LDYuMzYsMjQuMTMyLTAuNDINCgkJCWM2LjM4Ny02LjYxNCw2LjM4Ny0xNy4wOTksMC0yMy43MTJMMjI4LjkyOSwyMDUuMDF6Ii8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  width: 30px;
  height: 30px;
  border-radius: 2px;
}
.multiple-items_lines
  .httpHeader--line
  .httpHeader--line_remove.btn-option-icon {
  border: 0;
  padding: 6px;
  line-height: 100%;
}
.multiple-items_lines .httpHeader--line_add {
  font-size: 12px;
  text-decoration: underline;
  color: #f44336;
  text-align: right;
  cursor: pointer;
}
.multiple-items_textContentFormat {
}

.multiple-items_textContentFormat .textContentFormat__options {
  padding: 10px 0;
}

.multiple-items_textContentFormat .radio-group {
}

.multiple-items_textContentFormat .radio-group label {
  margin-right: 10px;
}

.multiple-items_textContentFormat .radio-group label .radio {
}

.multiple-items_textContentFormat .textContentFormat__appliedContent {
}

.multiple-items_textContentFormat
  .textContentFormat__appliedContent
  .textField {
  width: 100% !important;
}

/* ! RESPONSIVE DESIGN */
@media (max-width: 960px) {
  table.monitor_form--channel-alerts tr td:nth-child(4) {
    display: none;
  }
}
@media (max-width: 425px) {
  .monitor-regions ul li {
    flex-basis: 100%;
  }
}
